
import AppConfig      from '@/shared/services/app_config'
import Session        from '@/shared/services/session'
import Records        from '@/shared/services/records'
import EventBus       from '@/shared/services/event_bus'
import AbilityService from '@/shared/services/ability_service'
import LmoUrlService  from '@/shared/services/lmo_url_service'

export default
  props:
    poll: Object

  data: ->
    stance: null

  created: ->
    @watchRecords
      collections: ["stances"]
      query: (records) =>
        if @stance && !@stance.castAt && @poll.myStance() && @poll.myStance().castAt
          @stance = @lastStanceOrNew().clone()

        if !@stance && AbilityService.canParticipateInPoll(@poll)
          @stance = @lastStanceOrNew().clone()

  methods:
    lastStanceOrNew: ->
      @poll.myStance() || Records.stances.build(
        reasonFormat: Session.defaultFormat()
        pollId:    @poll.id,
        userId:    AppConfig.currentUserId
      ).choose(@$route.params.poll_option_id)

