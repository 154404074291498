
import Session from '@/shared/services/session'
import ChangeVolumeModalMixin from '@/mixins/change_volume_modal'
import GroupService from '@/shared/services/group_service'
import Flash from '@/shared/services/flash'
import { onError } from '@/shared/helpers/form'

export default
  mixins: [ChangeVolumeModalMixin]
  props:
    model: Object
    close: Function
    showClose:
      default: true
      type: Boolean

  data: ->
    includeInCatchUp: true
    volumeLevels: ["loud", "normal", "quiet"]
    isDisabled: false
    applyToAll: @defaultApplyToAll()
    volume: @defaultVolume()

  computed:
    formChanged: ->
      (@volume != @defaultVolume()) || (@applyToAll != @defaultApplyToAll())

  methods:
    submit: ->
      @model.saveVolume(@volume, @applyToAll)
      .then =>
        Flash.success 'change_volume_form.saved'
        @closeModal()
      .catch onError(@model)

    defaultApplyToAll: ->
      if @model.isA('user') then true else false

    defaultVolume: ->
      switch @model.constructor.singular
        when 'discussion' then @model.volume()
        when 'membership' then @model.volume
        when 'user'       then @model.defaultMembershipVolume

    labelFor: (volume) ->
      @$t("change_volume_form.simple.#{volume}_explain") + ' ('+@$t("change_volume_form.simple.#{volume}")+')'
      # @$t("change_volume_form.simple.#{volume}")+' - '+@$t("change_volume_form.simple.#{volume}_explain")

    translateKey: (key) ->
      if @model.isA('user')
        "change_volume_form.all_groups"
      else
        "change_volume_form.#{key || @model.constructor.singular}"


    groupName: ->
      if @model.groupName
        @model.groupName()
      else
        ''
    openGroupVolumeModal: ->
      @closeModal()
      setTimeout => GroupService.actions(@model.group(), @).change_volume.perform()

    openUserPreferences: ->
      @closeModal()
      @$router.push('/email_preferences')

