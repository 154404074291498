
import AppConfig from '@/shared/services/app_config'
import Session   from '@/shared/services/session'
import Records   from '@/shared/services/records'

export default
  props:
    poll: Object

  data: ->
    showHelpLink: AppConfig.features.app.help_link
    collapsed: !Session.user().hasExperienced(@experienceKey)

  created: ->
    if !Session.user().hasExperienced(@experienceKey)
      Records.users.saveExperience(@experienceKey)

  methods:
    hide: -> @collapsed = true

    show: -> @collapsed = false
  computed:
    experienceKey: -> @poll.pollType + "_tool_tip"

