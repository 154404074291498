
import Records        from '@/shared/services/records'
import EventBus       from '@/shared/services/event_bus'
import utils          from '@/shared/record_store/utils'
import LmoUrlService  from '@/shared/services/lmo_url_service'
import AbilityService from '@/shared/services/ability_service'
import Session from '@/shared/services/session'
import AppConfig      from '@/shared/services/app_config'
import Flash   from '@/shared/services/flash'
import {each , sortBy, includes, map, pull, uniq, throttle, debounce, merge} from 'lodash'
import { encodeParams } from '@/shared/helpers/encode_params'

export default
  props:
    close: Function
    model:
      type: Object
      required: true
  data: ->
    historyData: []
    historyLoading: false
    historyError: false
  created: ->
    @historyLoading = true
    Records.announcements.fetchHistoryFor(@model).then (data) =>
      @historyLoading = false
      @historyData = data || []
    , (err) =>
      @historyLoading = false
      @historyError = true
  computed:
    modelKind: -> @model.constructor.singular
    pollType: -> @model.pollType
    translatedPollType: -> @model.poll().translatedPollType() if @model.isA('poll') or @model.isA('outcome')
