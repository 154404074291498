
import EventBus from '@/shared/services/event_bus'

export default
  props:
    poll: Object
  methods:
    open: ->
      EventBus.$emit 'openModal',
        component: 'PollCommonAddOptionModal'
        props:
          poll: @poll.clone()
