
import GroupService from '@/shared/services/group_service'
import Records        from '@/shared/services/records'
import Session from '@/shared/services/session'
import EventBus from '@/shared/services/event_bus'
import AppConfig      from '@/shared/services/app_config'

export default
  data: ->
    group: Records.groups.fuzzyFind(@$route.params.key)
    actions: {}
  created: ->
    Records.groups.remote.fetchById(@group.id)
    EventBus.$emit 'currentComponent',
      page: 'groupPage'
      title: @group.name
      group: @group
      search:
        placeholder: @$t('navbar.search_members', name: @group.parentOrSelf().name)
    @watchRecords
      collections: ['memberships']
      query: =>
        @actions = GroupService.actions(@group, @)
        @membership = @group.membershipFor(Session.user())
  computed:
    showSubcriptionCard: -> AppConfig.features.app.subscriptions

