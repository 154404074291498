
import Session        from '@/shared/services/session'
import Records        from '@/shared/services/records'
import EventBus       from '@/shared/services/event_bus'
import AbilityService from '@/shared/services/ability_service'
import AppConfig      from '@/shared/services/app_config'
import LmoUrlService  from '@/shared/services/lmo_url_service'
import ChangeVolumeModalMixin from '@/mixins/change_volume_modal'
import { uniq, compact, concat, sortBy, map, pick } from 'lodash'
import UserService from '@/shared/services/user_service'
import Flash from '@/shared/services/flash'
import { onError } from '@/shared/helpers/form'

export default
  mixins: [ChangeVolumeModalMixin]
  data: ->
    newsletterEnabled: AppConfig.newsletterEnabled
    user: null
    groups: []
  created: ->
    @init()
    EventBus.$on 'signedIn', @init
    @watchRecords
      collections: ['groups', 'memberships']
      query: (store) =>
        groups = Session.user().groups()
        user = Session.user()
        @groups = sortBy groups, 'fullName'
  beforeDestroy: ->
    EventBus.$off 'signedIn', @init

  mounted: ->
    EventBus.$emit 'currentComponent', { titleKey: 'email_settings_page.header', page: 'emailSettingsPage'}

  methods:
    submit: ->
      Records.users.updateProfile(@user)
      .then =>
        Flash.success 'email_settings_page.messages.updated'
      .catch onError(@user)

    init: ->
      return unless Session.isSignedIn() or Session.user().restricted?
      Session.user().attributeNames.push('unsubscribeToken')
      @originalUser = Session.user()
      @user = Session.user().clone()

    groupVolume: (group) ->
      group.membershipFor(Session.user()).volume

    changeDefaultMembershipVolume: ->
      @openChangeVolumeModal(Session.user())

    editSpecificGroupVolume: (group) ->
      @openChangeVolumeModal(Session.user())
  computed:
    actions: -> pick UserService.actions(Session.user(), @), ['reactivate_user', 'deactivate_user']

    defaultSettingsDescription: ->
      "email_settings_page.default_settings.#{Session.user().defaultMembershipVolume}_description"
