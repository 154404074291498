import { render, staticRenderFns } from "./nav.vue?vue&type=template&id=8c9e6062&lang=pug&"
import script from "./nav.vue?vue&type=script&lang=coffee&"
export * from "./nav.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VNavigationDrawer})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Touch from 'vuetify/lib/directives/touch'
installDirectives(component, {Touch})
