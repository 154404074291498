
import OutcomeService from '@/shared/services/outcome_service'
import parseISO from 'date-fns/parseISO'
export default
  props:
    outcome: Object
  methods:
    parseISO: parseISO
  computed:
    actions: -> OutcomeService.actions(@outcome, @)

