
import colors from 'vuetify/lib/util/colors'
import {map, compact, pick} from 'lodash'
import { findActiveMarkAttribute } from '@/shared/tiptap_extentions/utils/mark'

ourColors = pick(colors, "red pink purple deepPurple indigo blue lightBlue cyan teal green lightGreen lime yellow amber orange deepOrange brown grey".split(" "))

export default
  props:
    commands: Object
    editor: Object

  data: ->
    back: null
    fore: null
    closable: false
    foreColors: ['#191919', '#ffffff'].concat(compact(map(ourColors, (value, key) => value.base)))
    backColors: ['#000000', '#ffffff'].concat(compact(map(ourColors, (value, key) => value.lighten3)))

  methods:
    onSelectFore: (color) ->
      @closable = true
      @fore = color
      @commands.foreColor({foreColor: color})

    onSelectBack: (color) ->
      @closable = true
      @back = color
      @commands.backColor({backColor: color})

  computed:
    activeBack: ->
      findActiveMarkAttribute(@editor.state, 'backColor')

    activeFore: ->
      findActiveMarkAttribute(@editor.state, 'foreColor')

