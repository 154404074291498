import { render, staticRenderFns } from "./form.vue?vue&type=template&id=7cececfc&lang=pug&"
import script from "./form.vue?vue&type=script&lang=coffee&"
export * from "./form.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import AuthEmailForm from '@/components/auth/email_form.vue'
import AuthProviderForm from '@/components/auth/provider_form.vue'
import DismissModalButton from '@/components/common/dismiss_modal_button.vue'
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {AuthEmailForm,AuthProviderForm,DismissModalButton,VCard,VCardTitle,VSheet,VSpacer})
