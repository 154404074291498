
import EventBus from '@/shared/services/event_bus'
import Records from '@/shared/services/records'
import { debounce, truncate, first, last, some, drop, min, compact } from 'lodash'

export default

  data: ->
    topDate: Date
    bottomDate: Date
    discussion: null
    open: null
    knobOffset: 0
    knobHeight: 32
    trackHeight: 400
    position: 1
    minUnitHeight: 24
    presets: []
    knobVisible: false

  mounted: ->
    EventBus.$on 'toggleThreadNav', => @open = !@open
    EventBus.$on 'scrollThreadNav', =>
      return if @knobVisible or !document.querySelector('.thread-sidebar .v-navigation-drawer__content')
      @$vuetify.goTo('.thread-nav__knob', { container: '.thread-sidebar .v-navigation-drawer__content', offset: 100 })

    EventBus.$on 'currentComponent', (options) =>
      @discussion = options.discussion
      return unless @discussion

      @watchRecords
        key: 'thread-nav'+@discussion.id
        collections: ["events", "discussions"]
        query: =>
          return unless @discussion && @discussion.createdEvent()
          @presets = Records.events.collection.chain()
            .find({pinned: true, discussionId: @discussion.id})
            .simplesort('position').data()
          @setHeight()
          if @discussion.newestFirst
            @topPosition = @childCount
            @topDate = @discussion.lastActivityAt

            @bottomPosition = 1
            @bottomDate = @discussion.createdAt
          else
            @topPosition = 1
            @topDate = @discussion.createdAt

            @bottomDate = @discussion.lastActivityAt
            @bottomPosition = @childCount

      # move this to activity panel.
      Records.events.fetch
        params:
          exclude_types: 'group discussion'
          discussion_id: @discussion.id
          pinned: true
          per: 200

    EventBus.$on 'visibleSlots', (slots) =>
      unless slots.length == 0
        if @discussion && @discussion.newestFirst
          @position = last(slots) || 1
        else
          @position = first(slots) || 1
        @knobOffset = @offsetFor(@position)
        @knobHeight = @unitHeight * (last(slots) - first(slots) + 1)

  methods:
    setKnobVisible: (visible) ->
      @knobVisible = visible

    setHeight: ->
      @trackHeight = 400
      while ((@minOffset() || @minUnitHeight) < @minUnitHeight) && (@trackHeight < 100000)
        @trackHeight = @trackHeight * 1.25

    minOffset: ->
      distances = [2..@presets.length].map (i) =>
        if @presets[i] && @presets[i-1]
          parseInt (@presets[i].position * @unitHeight) - (@presets[i-1].position * @unitHeight)
      min compact distances

    onTrackClicked: (event) ->
      @moveKnob(event)
      @goToPosition(@position)

    moveKnob: (event) ->
      event.preventDefault()
      event.stopImmediatePropagation()
      adjust = if @knobHeight < 64
           32
        else
          parseInt(@knobHeight / 2)

      @knobOffset = @getEventOffset(event)
      @position = @positionFor(@getEventOffset(event))

    getEventOffset: (event) ->
      # touch event structure differs from that of mouse event
      clientY = if event.touches
        event.touches[0].clientY
      else
        event.clientY

      offset = clientY - @$refs.slider.getBoundingClientRect().top
      if offset < 0
        0
      else if offset > @trackHeight
        @trackHeight
      else
        offset

    onMouseDown: ->
      onMouseMove = @moveKnob

      onMouseUp = =>
        document.removeEventListener 'mousemove', onMouseMove
        document.removeEventListener 'mouseup', onMouseUp
        @goToPosition(@position)

      document.addEventListener 'mousemove', onMouseMove
      document.addEventListener 'mouseup', onMouseUp

    onTouchStart: ->
      onTouchMove = @moveKnob

      onTouchEnd = =>
        document.removeEventListener 'touchmove', onTouchMove
        document.removeEventListener 'touchend', onTouchEnd
        @goToPosition(@position)

      document.addEventListener 'touchmove', onTouchMove, { passive: false }
      document.addEventListener 'touchend', onTouchEnd

    goToPosition: (position) ->
      unless (@$route.query && @$route.query.p == position)
        @$router.replace(query: {p: position}, params: {sequence_id: null, comment_id: null}).catch (err) => {}

    offsetFor: (position) ->
      if @discussion && @discussion.newestFirst
          @trackHeight - ((position) * @unitHeight)
        else
          (position - 1) * @unitHeight

    positionFor: (offset) ->
      return 1 unless @discussion
      position = parseInt(offset / @unitHeight) + 1
      position = if position < 1
          1
        else if position > @childCount
          @childCount
        else
          position

      if @discussion.newestFirst
        @childCount - position + 1
      else
        position

  watch:
    'discussion.newestFirst':
      immediate: true
      handler: ->
        return unless @discussion

  computed:
    unitHeight: ->
      @trackHeight / @childCount

    childCount: ->
      if @discussion && @discussion.createdEvent()
        @discussion.createdEvent().childCount
      else
        10

