var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"poll-count-form"},[_c('poll-common-form-fields',{attrs:{"poll":_vm.poll}}),_c('v-select',{attrs:{"item-avatar":"avatar","items":_vm.items,"multiple":"","chips":"","deletable-chips":"","label":_vm.$t('poll_common_form.options')},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [_c('v-chip',{key:item,attrs:{"pill":""}},[_c('v-avatar',{attrs:{"size":30}},[_c('img',{attrs:{"src":'/img/' + item + '.svg'}})]),_c('span',{directives:[{name:"t",rawName:"v-t",value:('poll_count_options.' + item),expression:"'poll_count_options.' + item"}]})],1)]}},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('v-list-item-avatar',[_c('img',{attrs:{"src":'/img/'+item+'.svg'}})]),_c('v-list-item-content',[_c('v-list-item-title',{directives:[{name:"t",rawName:"v-t",value:('poll_count_options.'+item),expression:"'poll_count_options.'+item"}]})],1)]}}]),model:{value:(_vm.poll.pollOptionNames),callback:function ($$v) {_vm.$set(_vm.poll, "pollOptionNames", $$v)},expression:"poll.pollOptionNames"}}),_c('poll-common-wip-field',{attrs:{"poll":_vm.poll}}),_c('poll-common-closing-at-field',{attrs:{"poll":_vm.poll}}),_c('poll-common-settings',{attrs:{"poll":_vm.poll}}),_c('common-notify-fields',{attrs:{"model":_vm.poll}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }