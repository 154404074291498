
import EventBus from '@/shared/services/event_bus'
import Records from '@/shared/services/records'
import Session from '@/shared/services/session'
import Flash   from '@/shared/services/flash'
import { onError } from '@/shared/helpers/form'
import {compact, map, toPairs, fromPairs, some, sortBy, isEqual} from 'lodash'

export default
  props:
    stance: Object
  data: ->
    stanceChoices: []
    pollOptions: []
    zone: null
    canRespondMaybe: null
    stanceValues: []

  beforeDestroy: ->
    EventBus.$off 'timeZoneSelected', @setTimeZone

  created: ->
    EventBus.$on 'timeZoneSelected', @setTimeZone
    @watchRecords
      collections: ['poll_options', 'poll']
      query: (records) =>
        @canRespondMaybe =  @stance.poll().customFields.can_respond_maybe
        @stanceValues = if @stance.poll().customFields.can_respond_maybe then [2,1,0] else [2, 0]
        if !isEqual map(@pollOptions, 'name'), map(@stance.poll().pollOptions(), 'name')
          @pollOptions = sortBy @stance.poll().pollOptions(), 'name'
          @stanceChoices = @pollOptions.map (option) =>
            lastChoice = @stance.stanceChoices().find((sc) => sc.pollOptionId == option.id) || {score: 0}
            id: option.id
            pollOption: => option
            poll: => @stance.poll()
            score: lastChoice.score

  methods:
    setTimeZone: (e, zone) ->
      @zone = zone

    submit: ->
      @stance.stanceChoicesAttributes = @stanceChoices.map (choice) ->
        {poll_option_id: choice.id, score: choice.score}

      actionName = if !@stance.castAt then 'created' else 'updated'
      @stance.save()
      .then =>
        @stance.poll().clearStaleStances()
        EventBus.$emit "closeModal"
        Flash.success "poll_#{@stance.poll().pollType}_vote_form.stance_#{actionName}"
      .catch onError(@stance)

    buttonStyleFor: (choice, score) ->
      if choice.score == score
        {opacity: 1}
      else
        {opacity: 0.3}

    imgForScore: (score) ->
      name = switch score
        when 2 then 'agree'
        when 1 then 'abstain'
        when 0 then 'disagree'
      "/img/#{name}.svg"

    incrementScore: (choice) ->
      if @canRespondMaybe
        choice.score = (choice.score + 5) % 3
      else
        choice.score = if choice.score == 2
          0
        else
          2

  computed:
    currentUserTimeZone: ->
      Session.user().timeZone


