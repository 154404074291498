
import { fieldFromTemplate } from '@/shared/helpers/poll'
import AppConfig from '@/shared/services/app_config'
import { optionColors, optionImages } from '@/shared/helpers/poll'

export default
  props:
    poll:
      type: Object
      required: true
    stanceChoice: Object
    size:
      type: Number
      default: 24
    hideScore:
      type: Boolean
      default: false

  data: ->
    optionColors: optionColors()
    optionImages: optionImages()

  computed:
    color: ->
      @pollOption.color

    pollOption: ->
      @stanceChoice.pollOption()

    pollType: ->
      @poll.pollType

    optionName: ->
      if @poll.translateOptionName()
        @$t('poll_' + @pollType + '_options.' + @stanceChoice.pollOption().name)
      else
        @stanceChoice.pollOption().name


  methods:
    emitClick: -> @$emit('click')

    colorFor: (score) ->
      switch score
        when 2 then AppConfig.pollColors.proposal[0]
        when 1 then AppConfig.pollColors.proposal[1]
        when 0 then AppConfig.pollColors.proposal[2]

