
import AppConfig from '@/shared/services/app_config'
export default
  props:
    poll: Object

  data: ->
    items: 'yes no'.split(' ')
    colors:
      yes: AppConfig.pollColors.count[0]
      no: AppConfig.pollColors.count[1]

