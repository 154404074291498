
import AppConfig    from '@/shared/services/app_config'
import Session      from '@/shared/services/session'
import Records      from '@/shared/services/records'
import EventBus     from '@/shared/services/event_bus'
import { fieldFromTemplate } from '@/shared/helpers/poll'
import {map, without} from 'lodash'

export default
  props:
    isModal:
      type: Boolean
      default: false
    discussion:
      type: Object
      default: => {}
    group: Object
  methods:
    openPollModal: (pollType) ->
      EventBus.$emit 'openModal',
        component: 'PollCommonModal'
        maxWidth: 710
        props:
          poll: @newPoll(pollType)

    pollTypes: ->
      if @isModal
        AppConfig.pollTypes
      else
        without AppConfig.pollTypes, 'proposal'

    newPoll: (pollType) ->
      Records.polls.build
        pollType: pollType
        discussionId: @discussion.id
        groupId: @discussion.groupId or @group.id
        pollOptionNames: map @callFieldFromTemplate(pollType, 'poll_options_attributes'), 'name'
        detailsFormat: Session.defaultFormat()

    callFieldFromTemplate: (pollType, field) ->
      fieldFromTemplate(pollType, field)

    getAriaLabelForPollType: (pollType) ->
      @$t("poll_types.#{pollType}")

