
import Records from '@/shared/services/records'
import Session from '@/shared/services/session'
import EventBus from '@/shared/services/event_bus'
import Flash  from '@/shared/services/flash'
import { onError } from '@/shared/helpers/form'
import { map, orderBy } from 'lodash'

export default
  props:
    poll: Object
    close: Function

  data: ->
    groupId: @poll.groupId
    groups: []

  mounted: ->
    parent = @poll.group().parentOrSelf()
    Records.groups.fetchByParent(parent).then =>
      adminGroups = [parent].concat(Records.groups.find(parentId: parent.id)).filter (group) =>
        group.adminsInclude(Session.user())

      sortedGroups = orderBy adminGroups, 'fullName'

      @groups = map adminGroups, (group) =>
        text: group.fullName
        value: group.id
        disabled: (group.id == @poll.groupId)

  methods:
    submit: ->
      @poll.setErrors({})
      @poll.groupId = @groupId
      @poll.save()
      .then (data) =>
        group = Records.groups.find(@groupId)
        Flash.success("poll_common_move_form.success", {poll_type: @poll.translatedPollType(), group: group.name})
        @close()
      .catch onError(@poll)

