import { render, staticRenderFns } from "./members_panel.vue?vue&type=template&id=da13a472&lang=pug&"
import script from "./members_panel.vue?vue&type=script&lang=coffee&"
export * from "./members_panel.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import Loading from '@/components/common/loading.vue'
import MembershipDropdown from '@/components/group/membership_dropdown.vue'
import ShareableLinkModal from '@/components/group/shareable_link_modal.vue'
import Space from '@/components/common/space.vue'
import TimeAgo from '@/components/common/time_ago.vue'
import UserAvatar from '@/components/user/avatar.vue'
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {Loading,MembershipDropdown,ShareableLinkModal,Space,TimeAgo,UserAvatar,VAlert,VBtn,VCard,VChip,VIcon,VLayout,VList,VListItem,VListItemAction,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle,VMenu,VTextField})
