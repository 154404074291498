

import { emojisByCategory, srcForEmoji, emojiSupported } from '@/shared/helpers/emojis'
import { each, keys, pick } from 'lodash'

export default
  props:
    insert:
      type: Function
      required: true

  data: ->
    search: ''
    emojiSupported: emojiSupported
    showMore: false

  methods:
    srcForEmoji: srcForEmoji

  computed:
    emojis: ->
      if @showMore
        emojisByCategory
      else
        pick(emojisByCategory, ['common', 'hands', 'expressions'])

