
import GroupService from '@/shared/services/group_service'
import Records        from '@/shared/services/records'
import Session from '@/shared/services/session'
import EventBus from '@/shared/services/event_bus'
import AppConfig      from '@/shared/services/app_config'
import RecipientsAutocomplete from '@/components/common/recipients_autocomplete'
import AbilityService from '@/shared/services/ability_service'
import Flash   from '@/shared/services/flash'
import { uniq, debounce } from 'lodash'

export default
  components:
    RecipientsAutocomplete: RecipientsAutocomplete

  props:
    group: Object

  data: ->
    query: ''
    recipients: []
    reset: false
    saving: false
    groupIds: [@group.id]
    excludedUserIds: []
    message: ''
    withMessage: false
    subscription: {}
    cannotInvite: false
    upgradeUrl: AppConfig.baseUrl + 'upgrade'

  mounted: ->
    @updateSuggestions()
    @watchRecords
      collections: ['memberships']
      query: (records) => @updateSuggestions()

    @subscription = @group.parentOrSelf().subscription
    @cannotInvite = !@subscription.active || (@subscription.max_members && @invitationsRemaining < 1)

  methods:
    fetchMemberships: debounce ->
      @loading = true
      Records.memberships.fetch
        params:
          exclude_types: 'group'
          q: @query
          group_id: @group.id
          per: 20
      .finally =>
        @loading = false
    , 300

    inviteRecipients: ->
      @saving = true
      Records.announcements.remote.post '',
        group_id: @group.id
        message: @message
        invited_group_ids: @groupIds
        recipient_emails: @recipients.filter((r) -> r.type == 'email').map((r) -> r.id)
        recipient_user_ids: @recipients.filter((r) -> r.type == 'user').map((r) -> r.id)

      .then (data) =>
        Flash.success('announcement.flash.success', { count: data.memberships.length })
        EventBus.$emit('closeModal')
      .finally =>
        @saving = false

    newQuery: (query) ->
      @query = query
      @fetchMemberships()
      @updateSuggestions()

    newRecipients: (recipients) -> @recipients = recipients

    updateSuggestions: ->
      @excludedUserIds = @group.memberIds()

    # findUsers: ->
    #   userIdsInGroup = Records.memberships.collection.
    #     chain().find(groupId: @group.id).
    #     data().map (m) -> m.userId
    #   membershipsChain = Records.memberships.collection.chain()
    #   membershipsChain = membershipsChain.find(groupId: { $in: @group.parentOrSelf().organisationIds() })
    #   membershipsChain = membershipsChain.find(userId: { $nin: userIdsInGroup })
    #
    #   userIdsNotInGroup = uniq membershipsChain.data().map((m) -> m.userId)
    #
    #   chain = Records.users.collection.chain()
    #   chain = chain.find(id: {$in: userIdsNotInGroup})
    #
    #
    #   if @query
    #     chain = chain.find
    #       $or: [
    #         {name: {'$regex': ["^#{@query}", "i"]}}
    #         {username: {'$regex': ["^#{@query}", "i"]}}
    #         {name: {'$regex': [" #{@query}", "i"]}}
    #       ]
    #   chain.data()

  computed:
    invitableGroups: ->
      @group.parentOrSelf().selfAndSubgroups().filter (g) -> AbilityService.canAddMembersToGroup(g)
    tooManyInvitations: ->
      @subscription.max_members && (@invitationsRemaining < 0)
    invitationsRemaining: ->
      (@subscription.max_members || 0) - @group.parentOrSelf().orgMembershipsCount - @recipients.length


