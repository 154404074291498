import { render, staticRenderFns } from "./page.vue?vue&type=template&id=1ab39ff1&lang=pug&"
import script from "./page.vue?vue&type=script&lang=coffee&"
export * from "./page.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import DiscussionForkActions from '@/components/discussion/fork_actions.vue'
import Loading from '@/components/common/loading.vue'
import ThreadCard from '@/components/thread/card.vue'
import ThreadCurrentPollBanner from '@/components/thread/current_poll_banner.vue'
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {DiscussionForkActions,Loading,ThreadCard,ThreadCurrentPollBanner,VBtn,VContainer,VMain,VProgressCircular})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Scroll from 'vuetify/lib/directives/scroll'
installDirectives(component, {Scroll})
