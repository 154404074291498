
import EventBus from '@/shared/services/event_bus'
import Records from '@/shared/services/records'
import Flash  from '@/shared/services/flash'
import RestfulClient from '@/shared/record_store/restful_client'
import { onError } from '@/shared/helpers/form'

export default
  props:
    close: Function
    group: Object

  data: ->
    idpMetadataUrl: ''
    samlProviderId: null
    remote: new RestfulClient('saml_providers')
    loading: true

  mounted: ->
    Records.samlProviders.fetch(params: {group_id: @group.id})
    .then (data) =>
      @idpMetadataUrl = data.idp_metadata_url
      @samlProviderId = data.saml_provider_id
      @spMetadataUrl = data.sp_metadata_url
    .catch =>
      # all good
    .finally =>
      @loading = false

  methods:
    submit: ->
      @loading = true
      @remote.create(idp_metadata_url: @idpMetadataUrl, group_id: @group.id)
      .then =>
        Flash.success 'configure_sso.success'
        @close()
      .catch =>
        Flash.error 'configure_sso.invalid_idp_metadata_url'
      .finally =>
        @loading = false

    destroy: ->
      @remote.destroy(@samlProviderId, group_id: @group.id)
      .then =>
        Flash.success 'configure_sso.destroyed'
        @close()
      .catch => alert "Something went wrong. Email contact@loomio.org for support."

