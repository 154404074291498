import { render, staticRenderFns } from "./stance_reason.vue?vue&type=template&id=b252d9f4&lang=pug&"
import script from "./stance_reason.vue?vue&type=script&lang=coffee&"
export * from "./stance_reason.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import LmoTextarea from '@/components/lmo_textarea/lmo_textarea.vue'
import ValidationErrors from '@/components/common/validation_errors.vue'
installComponents(component, {LmoTextarea,ValidationErrors})
