import { render, staticRenderFns } from "./outcome_created.vue?vue&type=template&id=63c0e52b&lang=pug&"
import script from "./outcome_created.vue?vue&type=script&lang=coffee&"
export * from "./outcome_created.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import FormattedText from '@/components/common/formatted_text.vue'
import ThreadItem from '@/components/thread/item.vue'
installComponents(component, {FormattedText,ThreadItem})
