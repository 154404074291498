
export default
  props:
    onClick: {
      type: Function
      default: null
    }
    elevation: {
      type: Number
      default: 0
    }
    group: Object
    size:
      default: '28px'
