
import EventBus       from '@/shared/services/event_bus'
import AbilityService from '@/shared/services/ability_service'
import prettyBytes from 'pretty-bytes'

export default
  props:
    attachments: [Array, Object]
    edit:
      default: null
      type: Function
  methods:
    prettifyBytes: (s) -> prettyBytes(s)
