
import AppConfig                from '@/shared/services/app_config'
import EventBus                 from '@/shared/services/event_bus'
import RecordLoader             from '@/shared/services/record_loader'
import AbilityService           from '@/shared/services/ability_service'
import Session from '@/shared/services/session'
import AuthModalMixin from '@/mixins/auth_modal'
import Records from '@/shared/services/records'
import { print } from '@/shared/helpers/window'
import { compact, snakeCase, camelCase, max, map } from 'lodash'
import ThreadActivityMixin from '@/mixins/thread_activity'

export default
  mixins: [ AuthModalMixin ]

  props:
    discussion: Object

  data: ->
    canAddComment: false
    currentAction: 'add-comment'
    newComment: null

  created: ->
    @init()
    EventBus.$on 'pollSaved', =>
      @currentAction = 'add-comment'

  beforeDestroy: ->
    EventBus.$off 'pollSaved'

  methods:
    init: ->
      @watchRecords
        key: @discussion.id
        collections: ['groups', 'memberships']
        query: (store) =>
          @canAddComment = AbilityService.canAddComment(@discussion)
      @reset()

    reset: ->
      @newComment = Records.comments.build
        bodyFormat: Session.defaultFormat()
        discussionId: @discussion.id
        authorId: Session.user().id

    signIn:     -> @openAuthModal()
    isLoggedIn: -> Session.isSignedIn()

  watch:
    'discussion.id': 'reset'

  computed:
    canStartPoll: ->
      AbilityService.canStartPoll(@discussion)

