
import Records from '@/shared/services/records'
import Flash   from '@/shared/services/flash'
import { exact }   from '@/shared/helpers/format_time'

import { format, utcToZonedTime } from 'date-fns-tz'
import { isSameYear, startOfHour }  from 'date-fns'

export default
  props:
    poll: Object

  created: ->
    Records.users.fetchTimeZones().then (data) => @zoneCounts = data

  data: ->
    value: startOfHour(new Date)
    min: new Date
    zoneCounts: []

  methods:
    addOption: ->
      if @poll.addOption(@value.toJSON())
        Flash.success('poll_meeting_form.time_slot_added')
      else
        Flash.error('poll_meeting_form.time_slot_already_added')

    timeInZone: (zone) -> exact(@value, zone)

