import { render, staticRenderFns } from "./chart_preview.vue?vue&type=template&id=2459d20c&lang=pug&"
import script from "./chart_preview.vue?vue&type=script&lang=coffee&"
export * from "./chart_preview.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import MatrixChart from '@/components/poll/meeting/matrix_chart.vue'
import PollProposalChartPreview from '@/components/poll/proposal/chart_preview.vue'
import ProgressChart from '@/components/poll/common/progress_chart.vue'
installComponents(component, {MatrixChart,PollProposalChartPreview,ProgressChart})
