
import AppConfig from '@/shared/services/app_config'
import EventBus from '@/shared/services/event_bus'
import Session from '@/shared/services/session'
import Records from '@/shared/services/records'
import marked from 'marked'
import md5 from 'md5'

export default
  data: ->
    notice: ''
    showNotice: true

  mounted: ->
    EventBus.$on 'systemNotice', (data) =>
      @notice = data.notice
      @showNotice = !Session.user().hasExperienced(md5(@notice))
    @notice = AppConfig.systemNotice || ''
    @showNotice = !Session.user().hasExperienced(md5(@notice))

  methods:
    hideNotice: ->
      Records.users.saveExperience(md5(@notice))
      @showNotice = false

