import { render, staticRenderFns } from "./page.vue?vue&type=template&id=4bd51771&lang=pug&"
import script from "./page.vue?vue&type=script&lang=coffee&"
export * from "./page.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import FormattedText from '@/components/common/formatted_text.vue'
import Loading from '@/components/common/loading.vue'
import Space from '@/components/common/space.vue'
import UserAvatar from '@/components/user/avatar.vue'
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {FormattedText,Loading,Space,UserAvatar,VAvatar,VCard,VCardText,VCardTitle,VContainer,VLayout,VList,VListItem,VListItemAvatar,VListItemTitle,VMain})
