
import EventBus from '@/shared/services/event_bus'
import Flash   from '@/shared/services/flash'
import { optionColors, optionImages } from '@/shared/helpers/poll'
import { onError } from '@/shared/helpers/form'

export default
  props:
    stance: Object
  data: ->
    selectedOptionId: @stance.pollOptionId()
    optionColors: optionColors()
    optionImages: optionImages()
    debug: @$route.query.debug
    optionGroups: []
  created: ->
    @watchRecords
      collections: ['poll_options']
      query: (records) =>
        options = @stance.poll().pollOptions()
        @optionGroups = if options.length == 4
          [[options[0], options[1]], [options[2], options[3]]]
        else
          [options]

  methods:
    submit: ->
      actionName = if !@stance.castAt then 'created' else 'updated'
      @stance.stanceChoicesAttributes = [{poll_option_id: @selectedOptionId}]
      @stance.save()
      .then =>
        @stance.poll().clearStaleStances()
        Flash.success "poll_#{@stance.poll().pollType}_vote_form.stance_#{actionName}"
        EventBus.$emit "closeModal"
      .catch onError(@stance)

    isSelected: (option) ->
      @selectedOptionId == option.id

    classes: (option) ->
      if @selectedOptionId
        if @selectedOptionId == option.id
          ['elevation-5']
        else
          ['poll-proposal-vote-form__button--not-selected', 'elevation-1']
      else
        ['elevation-1']

