
import AppConfig from '@/shared/services/app_config'
import { intersection } from 'lodash'
import { optionColors, optionImages } from '@/shared/helpers/poll'

export default
  props:
    poll: Object
    shouldReset: Boolean
  data: ->
    items: [
      {text: @$t('poll_proposal_options.agree_disagree'), value: ['agree', 'disagree']}
      {text: @$t('poll_proposal_options.agree_abstain_disagree'), value: ['agree', 'abstain', 'disagree']}
      {text: @$t('poll_proposal_options.agree_abstain_disagree_block'), value: ['agree', 'abstain', 'disagree', 'block']}
      {text: @$t('poll_proposal_options.consent_abstain_object'), value: ['consent', 'abstain', 'objection']}
    ]
    optionColors: optionColors()
    optionImages: optionImages()
