import { render, staticRenderFns } from "./renderer.vue?vue&type=template&id=c6e8bd44&lang=pug&"
import script from "./renderer.vue?vue&type=script&lang=coffee&"
export * from "./renderer.vue?vue&type=script&lang=coffee&"
import style0 from "./renderer.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import ThreadItemWrapper from '@/components/thread/item_wrapper.vue'
installComponents(component, {ThreadItemWrapper})
