
import Records from '@/shared/services/records'
import Session from '@/shared/services/session'
import FilesList from './files_list.vue'
import EventBus  from '@/shared/services/event_bus'

# import HtmlEditor from './html_editor'
import CollabEditor from './collab_editor'
import MdEditor from './md_editor'
import RescueUnsavedEditsService from '@/shared/services/rescue_unsaved_edits_service'

export default


  props:
    model: Object
    field: String
    label: String
    placeholder: String
    maxLength: Number
    autofocus: Boolean
    shouldReset: Boolean

  components:
    # 'html-editor': HtmlEditor
    'md-editor': MdEditor
    'collab-editor': CollabEditor

  mounted: ->
    RescueUnsavedEditsService.add(@model)

  computed:
    format: ->
      @model["#{@field}Format"]

    betaFeatures: ->
      false # Session.user().experiences['betaFeatures']

