
import { isNodeActive } from '@/shared/tiptap_extentions/utils/node'

export default
  props:
    commands: Object
    editor: Object

  methods:
    isActive: (alignment) ->
      isNodeActive(@editor.state, 'textAlign', alignment)

  computed:
    current: ->
      ['left', 'center', 'right'].find((v) => @isActive(v)) || "left"

    alignments: ->
      [
        { label: 'formatting.left_align', value: 'left', command: @commands.alignment },
        { label: 'formatting.center_align', value: 'center', command: @commands.alignment },
        { label: 'formatting.right_align', value: 'right', command: @commands.alignment },
      ]

