
import Session        from '@/shared/services/session'
import Records        from '@/shared/services/records'
import AbilityService from '@/shared/services/ability_service'
import Flash  from '@/shared/services/flash'
import { onError } from '@/shared/helpers/form'

export default
  props:
    group: Object
    close: Function

  methods:
    submit: ->
      @membershipRequest.save()
      .then =>
        Flash.success 'membership_request_form.messages.membership_requested', {group: @group.fullName}
        @close()
      .catch onError(@membershipRequest)

  data: ->
    membershipRequest: Records.membershipRequests.build
      groupId: @group.id
      name: Session.user().name
      email: Session.user().email
      introduction: null

  computed:
    isSignedIn: -> Session.isSignedIn()
