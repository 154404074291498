
import Session from '@/shared/services/session'
import { startOfHour, addDays } from 'date-fns'

export default
  props:
    poll: Object

  data: ->
    draft: !@poll.closingAt

  watch:
    draft: (val) ->
      if val
        @poll.closingAt = null
      else
        @poll.closingAt = startOfHour(addDays(new Date, 3))

