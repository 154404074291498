
import Records     from '@/shared/services/records'
import { times } from 'lodash'
import { hoursOfDay } from '@/shared/helpers/format_time'
import { format, parse, isValid } from 'date-fns'

export default
  props:
    value: Date
    min: Date
    dateLabel: Object
    timeLabel: Object

  created: ->
    @newValue = @value
    @dateStr = format(@value, 'yyyy-MM-dd')
    @timeStr = format(@value, 'HH:mm')
    @minStr  = format(@min, 'yyyy-MM-dd')

  data: ->
    dateStr: null
    timeStr: null
    dateMenu: false
    times: hoursOfDay
    validDate: (val) =>
      isValid(parse(val, "yyyy-MM-dd", new Date()))


  methods:
    updateNewValue: ->
      return unless isValid(parse("#{@dateStr} #{@timeStr}", 'yyyy-MM-dd HH:mm', new Date))
      @newValue = parse("#{@dateStr} #{@timeStr}", 'yyyy-MM-dd HH:mm', new Date)
      @$emit('input', @newValue)

  watch:
    dateStr: -> @updateNewValue()
    timeStr: -> @updateNewValue()

