
import AppConfig from '@/shared/services/app_config'
import { format, formatDistance, parse, startOfHour, isValid, addHours, isAfter } from 'date-fns'
import { hoursOfDay, exact} from '@/shared/helpers/format_time'

export default
  props:
    poll: Object

  data: ->
    closingHour: format(startOfHour(@poll.closingAt || new Date()), 'HH:mm')
    closingDate: format(@poll.closingAt || new Date(), 'yyyy-MM-dd')
    dateToday: format(new Date, 'yyyy-MM-dd')
    times: hoursOfDay
    timeZone: AppConfig.timeZone
    isShowingDatePicker: false
    validDate: => isValid(parse("#{@closingDate} #{@closingHour}", "yyyy-MM-dd HH:mm", new Date()))

  methods:
    exact: exact
    updateClosingAt: ->
      date = parse("#{@closingDate} #{@closingHour}", "yyyy-MM-dd HH:mm", new Date())
      if isValid(date)
        @poll.closingAt = date

  computed:
    label: ->
      return false unless @poll.closingAt
      formatDistance(@poll.closingAt, new Date, {addSuffix: true})

    closingSoonItems: ->
      'nobody author undecided_voters voters'.split(' ').map (name) =>
        {text: @$t("poll_common_settings.notify_on_closing_soon.#{name}"), value: name}

    reminderEnabled: ->
      !@poll.closingAt || isAfter(@poll.closingAt, addHours(new Date(), 24))

  watch:
    'poll.closingAt': (val) ->
      return unless val
      @closingHour = format(startOfHour(val), 'HH:mm')
      @closingDate = format(val, 'yyyy-MM-dd')

    closingDate: (val) ->
      @updateClosingAt()

    closingHour: (val) -> @updateClosingAt()
