
import EventBus from '@/shared/services/event_bus'
import Flash   from '@/shared/services/flash'
import { onError } from '@/shared/helpers/form'
import { sortBy, find, matchesProperty, take, map } from 'lodash'

export default
  props:
    stance: Object
  data: ->
    pollOptions: []

  created: ->
    @watchRecords
      collections: ['poll_options']
      query: (records) =>
        @pollOptions = @sortPollOptions(@stance.poll().pollOptions())

  methods:
    submit: ->
      selected = take @pollOptions, @numChoices
      @stance.stanceChoicesAttributes = map selected, (option, index) =>
        poll_option_id: option.id
        score:         @numChoices - index
      actionName = if !@stance.castAt then 'created' else 'updated'
      @stance.save()
      .then =>
        @stance.poll().clearStaleStances()
        Flash.success "poll_#{@stance.poll().pollType}_vote_form.stance_#{actionName}"
        EventBus.$emit "closeModal"
      .catch onError(@stance)

    sortPollOptions: (pollOptions) ->
      optionsByPriority = sortBy pollOptions, 'priority'
      sortBy optionsByPriority, (option) => -@scoreFor(option)

    scoreFor: (option) ->
      choice = find(@stance.stanceChoices(), matchesProperty('pollOptionId', option.id))
      (choice or {}).score
  computed:
    numChoices: -> @stance.poll().customFields.minimum_stance_choices

