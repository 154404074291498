import { render, staticRenderFns } from "./outcome_created.vue?vue&type=template&id=a697f0c0&lang=pug&"
import script from "./outcome_created.vue?vue&type=script&lang=coffee&"
export * from "./outcome_created.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import ActionDock from '@/components/common/action_dock.vue'
import AttachmentList from '@/components/thread/attachment_list.vue'
import DocumentList from '@/components/document/list.vue'
import FormattedText from '@/components/common/formatted_text.vue'
import StrandItemHeadline from '@/components/strand/item/headline.vue'
installComponents(component, {ActionDock,AttachmentList,DocumentList,FormattedText,StrandItemHeadline})
