
import EventBus from '@/shared/services/event_bus'
import Records from '@/shared/services/records'
import Session from '@/shared/services/session'
import Flash from '@/shared/services/flash'
import RecipientsAutocomplete from '@/components/common/recipients_autocomplete'
import DiscussionReaderService from '@/shared/services/discussion_reader_service'
import {map, debounce, without, filter, uniq, uniqBy, some, pick} from 'lodash'

export default
  components:
    RecipientsAutocomplete: RecipientsAutocomplete

  props:
    discussion: Object

  data: ->
    readers: []
    query: ''
    searchResults: []
    recipients: []
    membershipsByUserId: {}
    readerUserIds: []
    reset: false
    saving: false
    message: ''
    actionNames: []
    service: DiscussionReaderService

  mounted: ->
    @actionNames = ['makeAdmin', 'removeAdmin', 'revoke'] # 'resend'

    @watchRecords
      collections: ['discussionReaders', 'memberships']
      query: (records) => @updateReaders()

  computed:
    hasRecipients: ->
      @discussion.recipientAudience ||
      @discussion.recipientUserIds.length ||
      @discussion.recipientEmails.length

    model: -> @discussion

    excludedUserIds: ->
      @readerUserIds.concat(Session.user().id)

  methods:
    isGroupAdmin: (reader) ->
      @membershipsByUserId[reader.userId] && @membershipsByUserId[reader.userId].admin

    isGuest: (reader) ->
      !@membershipsByUserId[reader.userId]

    inviteRecipients: ->
      count = @recipients.length
      @saving = true
      params = Object.assign
        discussion_id: @discussion.id
      ,
        recipient_audience: @discussion.recipientAudience
        recipient_user_ids: @discussion.recipientUserIds
        recipient_emails: @discussion.recipientEmails
        recipient_message: @message
      Records.announcements.remote.post '', params
      .then =>
        @reset = !@reset
        Flash.success('announcement.flash.success', { count: count })
      .finally =>
        @saving = false

    newQuery: (query) ->
      @query = query
      @updateReaders()
      @fetchReaders()

    newRecipients: (recipients) -> @recipients = recipients

    fetchReaders: debounce ->
      Records.discussionReaders.fetch
        params:
          exclude_types: 'discussion'
          query: @query
          discussion_id: @discussion.id
      .then (records) =>
        userIds = map records['users'], 'id'
        Records.memberships.fetch
          params:
            exclude_types: 'group inviter'
            group_id: @discussion.groupId
            user_xids: userIds.join('x')
      .finally => @updateReaders()
    , 300

    updateReaders: ->
      chain = Records.discussionReaders.collection.chain().
              find(discussionId: @discussion.id).
              find(revokedAt: null)

      if @query
        users = Records.users.collection.find
          $or: [
            {name: {'$regex': ["^#{@query}", "i"]}},
            {email: {'$regex': ["#{@query}", "i"]}},
            {username: {'$regex': ["^#{@query}", "i"]}},
            {name: {'$regex': [" #{@query}", "i"]}}
          ]
        chain = chain.find(userId: {$in: map(users, 'id')})

      chain = chain.simplesort('id', true)
      @readers = chain.data()
      @readerUserIds = map(Records.discussionReaders.collection.find(discussionId: @discussion.id), 'userId')

      @membershipsByUserId = {}
      Records.memberships.collection.find(userId: {$in: @readerUserIds},
                                          groupId: @discussion.groupId).forEach (m) =>
        @membershipsByUserId[m.userId] = m

