
import Records  from '@/shared/services/records'
import EventBus from '@/shared/services/event_bus'
import Flash   from '@/shared/services/flash'
import { sum, map, head, filter, without, sortBy, isEqual } from 'lodash'
import { onError } from '@/shared/helpers/form'

export default
  props:
    stance: Object

  data: ->
    pollOptions: []
    stanceChoices: []

  created: ->
    @watchRecords
      collections: ['poll_options']
      query: (records) =>
        if !isEqual map(@pollOptions, 'name'), map(@stance.poll().pollOptions(), 'name')
          @pollOptions = @stance.poll().pollOptions()
          @stanceChoices = map @pollOptions, (option) =>
            poll_option_id: option.id
            score: @stanceChoiceFor(option).score
            name: option.name

  methods:
    submit: ->
      if sum(map(@stanceChoices, 'score')) > 0
        @stance.stanceChoicesAttributes = map @stanceChoices, (choice) =>
          poll_option_id: choice.poll_option_id
          score: choice.score
      actionName = if !@stance.castAt then 'created' else 'updated'
      @stance.save()
      .then =>
        @stance.poll().clearStaleStances()
        Flash.success "poll_#{@stance.poll().pollType}_vote_form.stance_#{actionName}"
        EventBus.$emit "closeModal"
      .catch onError(@stance)

    rulesForChoice: (choice) ->
      [(v) => (v <= @maxForChoice(choice)) || @$t('poll_dot_vote_vote_form.too_many_dots')]

    percentageFor: (choice) ->
      max = dotsPerPerson
      return unless max > 0
      "#{100 * choice.score / max}% 100%"

    backgroundImageFor: (option) ->
      "url(/img/poll_backgrounds/#{option.color.replace('#','')}.png)"

    styleData: (choice) ->
      option = @optionFor(choice)
      'border-color': option.color
      'background-image': @backgroundImageFor(option)
      'background-size': @percentageFor(choice)

    stanceChoiceFor: (option) ->
      head(filter(@stance.stanceChoices(), (choice) =>
        choice.pollOptionId == option.id
        ).concat({score: 0}))

    adjust: (choice, amount) ->
      choice.score += amount

    optionFor: (choice) ->
      Records.pollOptions.find(choice.poll_option_id)

    maxForChoice: (choice) ->
      @dotsPerPerson - sum(map(without(@stanceChoices, choice), 'score'))

  computed:
    dotsRemaining: ->
      @dotsPerPerson - sum(map(@stanceChoices, 'score'))

    dotsPerPerson: ->
      @stance.poll().customFields.dots_per_person

    orderedStanceChoices: -> sortBy @stanceChoices, 'name'

