
import ThreadService  from '@/shared/services/thread_service'
import { map, compact, pick } from 'lodash'
import EventBus from '@/shared/services/event_bus'
import openModal      from '@/shared/helpers/open_modal'

export default
  props:
    discussion: Object

  data: ->
    actions: ThreadService.actions(@discussion, @)

  mounted: ->
    @discussion.fetchUsersNotifiedCount()

  computed:
    arrangementAction: -> @actions['edit_arrangement']

    editThread: -> @actions['edit_thread']

    dockActions: ->
      pick @actions, ['react', 'translate_thread', 'add_comment', 'subscribe', 'unsubscribe', 'unignore', 'edit_thread', 'announce_thread']

    menuActions: ->
      pick @actions, ['show_history',  'notification_history', 'close_thread', 'reopen_thread', 'move_thread', 'discard_thread', 'export_thread']

    status: ->
      return 'pinned' if @discussion.pinned

    statusTitle: ->
      @$t("context_panel.thread_status.#{@status}")

    groups: ->
      if @discussion.groupId
        map @discussion.group().parentsAndSelf(), (group) =>
          text: group.name
          disabled: false
          to: @urlFor(group)
      else
        [{
          text: @$t('discussion_form.invite_only_thread')
          disabled: false
          to: '/threads/direct'
        }]

  methods:
    viewed: (viewed) ->
      @discussion.markAsSeen() if viewed

    openArrangementForm: -> @actions['edit_arrangement'].perform()

    openSeenByModal: ->
      openModal
        component: 'SeenByModal'
        props:
          discussion: @discussion

