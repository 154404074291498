
import Flash  from '@/shared/services/flash'
import LmoUrlService from '@/shared/services/lmo_url_service'
import EventBus from '@/shared/services/event_bus'

export default
  props:
    close:
      type: Function
      default: ->
        console.log 'closing time'
        EventBus.$emit('closeModal')
    confirm: Object

  data: ->
    isDisabled: false
    confirmText: ''

  methods:
    submit: ->
      @isDisabled = true
      @confirm.submit().then =>
        @close()
        @$router.push "#{@confirm.redirect}"     if @confirm.redirect?
        @confirm.successCallback()        if typeof @confirm.successCallback is 'function'
        Flash.success @confirm.text.flash if @confirm.text.flash
      .finally =>
        @isDisabled = false

  computed:
    canProceed: ->
      if @confirm.text.confirm_text
        @confirmText.trim()  == @confirm.text.confirm_text.trim()
      else
        true

