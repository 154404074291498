
import EventBus from '@/shared/services/event_bus'
import { iconFor }          from '@/shared/helpers/poll'
import PollCommonDirective from '@/components/poll/common/directive.vue'
import Flash   from '@/shared/services/flash'
import { sortBy } from 'lodash'
import { onError } from '@/shared/helpers/form'

export default
  props:
    stance: Object
  components:
    PollCommonDirective: PollCommonDirective

  methods:
    submit: ->
      actionName = if !@stance.castAt then 'created' else 'updated'
      @stance.save()
      .then =>
        @stance.poll().clearStaleStances()
        Flash.success "poll_#{@stance.poll().pollType}_vote_form.stance_#{actionName}"
        @close()
      .catch onError(@stance)

  computed:
    icon: ->
      iconFor(@stance.poll())
    orderedStanceChoices: ->
      sortBy(@stance.stanceChoices(), 'rank')
