
import { fieldFromTemplate, myLastStanceFor } from '@/shared/helpers/poll'

import BarChart from '@/components/poll/common/bar_chart.vue'
import ProcessChart from '@/components/poll/common/progress_chart.vue'
import PollProposalChartPreview from '@/components/poll/proposal/chart_preview.vue'
import MatrixChart from '@/components/poll/meeting/matrix_chart.vue'

export default
  components:
    BarChart: BarChart
  props:
    poll: Object
    showMyStance:
      type: Boolean
      default: true
    size:
      type: Number
      default: 48
  computed:
    chartType: -> fieldFromTemplate(@poll.pollType, 'chart_type')
    myStance: -> myLastStanceFor(@poll)
