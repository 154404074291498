import { render, staticRenderFns } from "./notifications_count.vue?vue&type=template&id=53f2a65e&lang=pug&"
import script from "./notifications_count.vue?vue&type=script&lang=coffee&"
export * from "./notifications_count.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import Space from '@/components/common/space.vue'
installComponents(component, {Space})
