
import AppConfig from '@/shared/services/app_config'
import Records   from '@/shared/services/records'

export default
  props:
    poll: Object
  data: ->
    colors: AppConfig.pollColors.count
  methods:
    percentComplete: (index) ->
      "#{100 * @poll.stanceCounts[index] / @poll.votersCount}%"
