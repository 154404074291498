import { render, staticRenderFns } from "./context_panel.vue?vue&type=template&id=162d75fd&lang=pug&"
import script from "./context_panel.vue?vue&type=script&lang=coffee&"
export * from "./context_panel.vue?vue&type=script&lang=coffee&"
import style0 from "./context_panel.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import ActionDock from '@/components/common/action_dock.vue'
import AttachmentList from '@/components/thread/attachment_list.vue'
import DocumentList from '@/components/document/list.vue'
import FormattedText from '@/components/common/formatted_text.vue'
import MidDot from '@/components/common/mid_dot.vue'
import TagsDisplay from '@/components/tags/display.vue'
import TimeAgo from '@/components/common/time_ago.vue'
import Translation from '@/components/common/translation.vue'
import UserAvatar from '@/components/user/avatar.vue'
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {ActionDock,AttachmentList,DocumentList,FormattedText,MidDot,TagsDisplay,TimeAgo,Translation,UserAvatar,VBreadcrumbs,VDivider,VLayout,VSpacer,VTooltip})
