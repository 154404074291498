
import StrandList from '@/components/strand/list.vue'
import NewComment from '@/components/strand/item/new_comment.vue'
import NewDiscussion from '@/components/strand/item/new_discussion.vue'
import DiscussionEdited from '@/components/strand/item/discussion_edited.vue'
import PollCreated from '@/components/strand/item/poll_created.vue'
import StanceCreated from '@/components/strand/item/stance_created.vue'
import OutcomeCreated from '@/components/strand/item/outcome_created.vue'
import StrandLoadMore from '@/components/strand/load_more.vue'
import OtherKind from '@/components/strand/item/other_kind.vue'
import RangeSet from '@/shared/services/range_set'
import EventBus from '@/shared/services/event_bus'
import { camelCase, first, last, some, sortedUniq, sortBy, without } from 'lodash'

export default
  name: 'strand-list'
  props:
    loader: Object
    collection:
      type: Array
      required: true

  components:
    StrandList: StrandList
    NewDiscussion: NewDiscussion
    NewComment: NewComment
    PollCreated: PollCreated
    StanceCreated: StanceCreated
    OutcomeCreated: OutcomeCreated
    OtherKind: OtherKind
    StrandLoadMore: StrandLoadMore
    DiscussionEdited: DiscussionEdited

  computed:
    parentExists: ->
      @collection[0] && @collection[0].event && @collection[0].event.parent()

    lastPosition: ->
      (@parentExists && @collection[0].event.parent().childCount) || 0

    positions: ->
      @collection.map (e) -> e.event.position

    ranges: ->
      RangeSet.arrayToRanges(@positions)

    siblingCount: ->
      (@collection &&
      @collection.length &&
      @collection[0].event.parent() &&
      @collection[0].event.parent().childCount) || 1

  methods:
    isFocused: (event) ->
      (event.depth == 1 && event.position == @loader.focusAttrs.position) ||
      (event.positionKey == @loader.focusAttrs.positionKey) ||
      (event.sequenceId == @loader.focusAttrs.sequenceId) ||
      (event.eventableType == 'Comment' && event.eventableId == @loader.focusAttrs.commentId)

    isUnread: (event) ->
      if event.kind == "new_discussion"
        @loader.discussion.updatedAt > @loader.discussion.lastReadAt
      else
        !RangeSet.includesValue(@loader.readRanges, event.sequenceId)

    positionKeyPrefix: (event) ->
      if event.depth < 1
        event.positionKey.split('-').slice(0, event.depth - 1)
      else
        null

    isFirstInRange: (pos) ->
      some(@ranges, (range) -> range[0] == pos)

    countEarlierMissing: (pos) ->
      lastPos = 1
      val = 0
      @ranges.forEach (range) ->
        if range[0] == pos
          val = (pos - lastPos)
        else
          lastPos = range[1]
      val

    countLaterMissing: ->
      @lastPosition - last(@ranges)[1]

    isLastInLastRange: (pos) ->
      last(@ranges)[1] == pos

    componentForKind: (kind) ->
      camelCase if ['stance_created', 'discussion_edited', 'new_comment', 'outcome_created', 'poll_created', 'new_discussion'].includes(kind)
        kind
      else
        'other_kind'

    classes: (event) ->
      return [] unless event
      ["lmo-action-dock-wrapper", "positionKey-#{event.positionKey}", "sequenceId-#{event.sequenceId}", "position-#{event.position}"]

