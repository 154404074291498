
import AuthService from '@/shared/services/auth_service'
import EventBus    from '@/shared/services/event_bus'

export default
  props:
    user: Object
  data: ->
    email: @user.email
    loading: false
    
  watch:
    # GK: NB: not sure why this hack is necessary, but email is not set otherwise
    'user.email': ->
      @email = @user.email
  methods:
    submit: ->
      return unless @validateEmail()
      @user.email = @email
      @loading = true
      AuthService.emailStatus(@user).finally => @loading = false
    validateEmail: ->
      @user.errors = {}
      if !@email
        @user.errors.email = [@$t('auth_form.email_not_present')]
      else if !@email.match(/[^\s,;<>]+?@[^\s,;<>]+\.[^\s,;<>]+/g)
        @user.errors.email = [@$t('auth_form.invalid_email')]
      !@user.errors.email?
