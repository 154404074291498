
import Flash from '@/shared/services/flash'
import EventBus from '@/shared/services/event_bus'
import { onError } from '@/shared/helpers/form'

export default
  props:
    event: Object

  data: ->
    title: null
    loading: false

  mounted: ->
    @title = (window.getSelection() && window.getSelection().toString()) || @event.pinnedTitle || @event.suggestedTitle()
    @$nextTick => @$refs.focus.focus()

  methods:
    submit: ->
      @loading = true
      @event.pin(@title)
      .then =>
        Flash.success('activity_card.event_pinned')
        EventBus.$emit('closeModal')
      .catch onError(@event)

