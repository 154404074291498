
import AppConfig from '@/shared/services/app_config'
import AbilityService from '@/shared/services/ability_service'
import { find } from 'lodash'

export default
  props:
    discussion: Object

  data: ->
    poll: null

  created: ->
    @watchRecords
      collections: ['polls', 'stances']
      query: (store) =>
        @poll = find @discussion.activePolls().filter((poll) -> poll.pollOptionNames.length), (poll) ->
          AbilityService.canParticipateInPoll(poll) && !poll.iHaveVoted()

  computed:
    styles: ->
      { bar, top } = @$vuetify.application
      return
        display: 'flex'
        position: 'sticky'
        top: "#{bar + top}px"
        zIndex: 1
    event: ->
      @poll && @poll.createdEvent()


