
import RecordLoader from '@/shared/services/record_loader'
import Records from '@/shared/services/records'
import EventBus     from '@/shared/services/event_bus'
import { fieldFromTemplate } from '@/shared/helpers/poll'

export default
  components:
    PollCommonDirective: -> import('@/components/poll/common/directive')

  props:
    poll: Object

  data: ->
    stances: []
    order: 'newest_first'
    sortOptions:
      [
        {text: @$t('poll_common_votes_panel.newest_first'), value: "newest_first"}
        {text: @$t('poll_common_votes_panel.undecided_first'), value: "undecided_first"}
      ]

  created: ->
    @refresh()

    @watchRecords
      collections: ['stances']
      query: => @findRecords()

  computed:
    latestStances: ->
      @stances.filter (stance) -> stance.latest

  methods:
    findRecords: ->
      chain = Records.stances.collection.chain().
        find(pollId: @poll.id).
        find(latest: true).
        find(revokedAt: null)
      chain = switch @order
        when 'newest_first'
          chain.simplesort('castAt', true)
        when 'undecided_first'
          chain.simplesort('castAt', false)
      @stances = chain.data()

    refresh: ->
      @initLoader().fetchRecords()

    initLoader: ->
      @loader = new RecordLoader
        collection: 'stances'
        params:
          poll_id: @poll.id
          order: {
            newest_first: "cast_at DESC NULLS LAST"
            undecided_first: "cast_at DESC NULLS FIRST"
          }[@order]

