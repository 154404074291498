
import AppConfig from '@/shared/services/app_config'
import Records   from '@/shared/services/records'
import { max, values, orderBy, map, find } from 'lodash'

export default
  props:
    poll: Object
  computed:
    rankedOptions: ->
      sortedByScore = orderBy map(@poll.stanceData, (score, name) =>
        name: name
        score: score
        poll: => @poll
        pollOption: => find(@poll.pollOptions(), (option) -> option.name == name)
      )
      ,
        'score', 'desc'

      sortedByScore.forEach (option, index) =>
        option.rank = index+1
        option.rankOrScore = index+1

      sortedByScore

