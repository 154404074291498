
import Records        from '@/shared/services/records'
import EventBus       from '@/shared/services/event_bus'
import AbilityService from '@/shared/services/ability_service'
import { debounce, some, every } from 'lodash'

export default
  data: ->
    group: null
    loading: true

  created: ->
    @onQueryInput = debounce (val) =>
      @$router.replace(@mergeQuery(q: val))
    , 500

    Records.groups.findOrFetch(@$route.params.key).then (group) =>
      @group = group

      EventBus.$emit 'currentComponent',
        page: 'groupPage'
        title: @group.name
        group: @group

      Records.groups.fetchByParent(@group).then =>
        @loading = false
        EventBus.$emit 'subgroupsLoaded', @group

      @watchRecords
        collections: ['memberships', 'groups']
        query: @findRecords

      @findRecords()

  computed:
    canCreateSubgroups: ->
      AbilityService.canCreateSubgroups(@group)

  methods:
    findRecords: ->
      chain = Records.groups.collection.chain().
                     find(parentId: @group.id).
                     simplesort('name')

      if @$route.query.q
        chain = chain.where (group) =>
          some [group.name, group.description], (field) =>
            RegExp(@$route.query.q, "i").test(field)

      @subgroups = chain.data()

    startSubgroup: ->
      EventBus.$emit('openModal',
                      component: 'GroupNewForm',
                      props: {
                        group: Records.groups.build(parentId: @group.id)
                      })


    stripDescription: (description) -> (description || '').replace(///<[^>]*>?///gm, '')

  watch:
    '$route.query': 'findRecords'
