import { render, staticRenderFns } from "./vote_form.vue?vue&type=template&id=006e8349&lang=pug&"
import script from "./vote_form.vue?vue&type=script&lang=coffee&"
export * from "./vote_form.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import PollCommonAddOptionButton from '@/components/poll/common/add_option_button.vue'
import PollCommonStanceReason from '@/components/poll/common/stance_reason.vue'
import SubmitOverlay from '@/components/common/submit_overlay.vue'
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import ValidationErrors from '@/components/common/validation_errors.vue'
installComponents(component, {PollCommonAddOptionButton,PollCommonStanceReason,SubmitOverlay,VBtn,VCardActions,VCheckbox,VRadio,VRadioGroup,ValidationErrors})
