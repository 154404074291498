import { render, staticRenderFns } from "./event_children.vue?vue&type=template&id=927f83fa&lang=pug&"
import script from "./event_children.vue?vue&type=script&lang=coffee&"
export * from "./event_children.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import ThreadRenderer from '@/components/thread/renderer.vue'
installComponents(component, {ThreadRenderer})
