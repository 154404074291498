
import Records  from '@/shared/services/records'
import EventBus from '@/shared/services/event_bus'
import Flash   from '@/shared/services/flash'
import { onError } from '@/shared/helpers/form'
import { head, filter, map, sortBy, isEqual } from 'lodash'

export default
  props:
    stance: Object

  data: ->
    pollOptions: []
    stanceChoices: []

  created: ->
    @watchRecords
      collections: ['poll_options']
      query: (records) =>
        if !isEqual map(@pollOptions, 'name'), map(@stance.poll().pollOptions(), 'name')
          @pollOptions = @poll.pollOptions()
          @stanceChoices = map @pollOptions, (option) =>
              poll_option_id: option.id
              score: @stanceChoiceFor(option).score
              name: option.name
  methods:
    submit: ->
      @stance.stanceChoicesAttributes = map @stanceChoices, (choice) =>
        poll_option_id: choice.poll_option_id
        score: choice.score
      actionName = if !@stance.castAt then 'created' else 'updated'
      @stance.save()
      .then =>
        @stance.poll().clearStaleStances()
        Flash.success "poll_#{@stance.poll().pollType}_vote_form.stance_#{actionName}"
        EventBus.$emit "closeModal"
      .catch onError(@stance)

    stanceChoiceFor: (option) ->
      head(filter(@stance.stanceChoices(), (choice) =>
        choice.pollOptionId == option.id
        ).concat({score: 0}))

    optionFor: (choice) ->
      Records.pollOptions.find(choice.poll_option_id)

  computed:
    poll: -> @stance.poll()

    orderedStanceChoices: -> sortBy @stanceChoices, 'name'
