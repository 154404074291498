
import AppConfig          from '@/shared/services/app_config'
import Records            from '@/shared/services/records'
import Session            from '@/shared/services/session'
import EventBus           from '@/shared/services/event_bus'
import AbilityService     from '@/shared/services/ability_service'
import RecordLoader       from '@/shared/services/record_loader'
import ThreadFilter       from '@/shared/services/thread_filter'
import { capitalize, take, keys, every, orderBy, debounce } from 'lodash'
import { subDays, addDays, subWeeks, subMonths } from 'date-fns'

export default
  data: ->
    dashboardLoaded: Records.discussions.collection.data.length > 0
    filter: @$route.params.filter || 'hide_muted'
    views:
      proposals: []
      today: []
      yesterday: []
      thisweek: []
      thismonth: []
      older: []
    loader: null
    searchLoader: null
    searchResults: []

  created: ->
    @onQueryInput = debounce (val) =>
      @$router.replace(@mergeQuery(q: val))
    , 500

    @init()
    EventBus.$on 'signedIn', @init

  beforeDestroy: ->
    EventBus.$off 'signedIn', @init

  mounted: ->
    EventBus.$emit('content-title-visible', false)
    EventBus.$emit 'currentComponent',
      titleKey: @titleKey
      page: 'dashboardPage'
      search:
        placeholder: @$t('navbar.search_all_threads')

  watch:
    '$route.query': 'refresh'

  methods:
    init: ->
      @loader = new RecordLoader
        collection: 'discussions'
        path: 'dashboard'
        params:
          exclude_types: 'group'
          filter: @filter
          per: 50

      @searchLoader = new RecordLoader
        collection: 'searchResults'

      @watchRecords
        key: 'dashboard'
        collections: ['discussions', 'searchResults']
        query: @query

      @refresh()

    refresh: ->
      return unless Session.isSignedIn()
      @fetch()
      @query()

    fetch: ->
      return unless @loader
      if @$route.query.q
        @searchLoader.fetchRecords(q: @$route.query.q).then =>
          @dashboardLoaded = true
          @query()
      else
        @loader.fetchRecords().then => @dashboardLoaded = true

    query: ->
      if @$route.query.q
        chain = Records.searchResults.collection.chain()
        chain = chain.find(query: @$route.query.q).data()
        @searchResults = orderBy(chain, 'rank', 'desc')
      else
        now = new Date()
        @views.proposals = ThreadFilter(Records, filters: @filters('show_proposals'))
        @views.today     = ThreadFilter(Records, filters: @filters('hide_proposals'), from: subDays(now, 1), to: addDays(now, 10000))
        @views.yesterday = ThreadFilter(Records, filters: @filters('hide_proposals'), from: subDays(now, 2), to: subDays(now, 1))
        @views.thisweek  = ThreadFilter(Records, filters: @filters('hide_proposals'), from: subWeeks(now, 1), to: subDays(now, 2))
        @views.thismonth = ThreadFilter(Records, filters: @filters('hide_proposals'), from: subMonths(now, 1),  to: subWeeks(now, 1))
        @views.older     = ThreadFilter(Records, filters: @filters('hide_proposals'), from: subMonths(now, 12),  to: subMonths(now, 1))

    viewName: (name) ->
      if @filter == 'show_muted'
        "dashboard#{capitalize(name)}Muted"
      else
        "dashboard#{capitalize(name)}"

    filters: (filters) ->
      ['only_threads_in_my_groups', 'show_opened', @filter].concat(filters)


  computed:
    titleKey: ->
      if @filter == 'show_muted'
        'dashboard_page.filtering.muted'
      else
        'dashboard_page.filtering.all'

    viewNames: -> keys(@views)
    loadingViewNames: -> take @viewNames, 3
    noGroups: -> Session.user().groups().length == 0
    promptStart: ->
      @noGroups && AbilityService.canStartGroups()
    noThreads: -> every @views, (view) => view.length == 0
    userHasMuted: -> Session.user().hasExperienced("mutingThread")
    showLargeImage: -> true

