
import Session        from '@/shared/services/session'
import Records        from '@/shared/services/records'
import Flash   from '@/shared/services/flash'
import { onError } from '@/shared/helpers/form'

export default
  props:
    user: Object
    close: Function
  data: ->
    processing: false

  created: ->
    @user.password = ''
    @user.passwordConfirmation = ''

  methods:
    submit: ->
      Records.users.updateProfile(@user)
      .then =>
        Flash.success "change_password_form.password_changed"
        @close()
      .catch onError(@user)

