
import PollService    from '@/shared/services/poll_service'
import AbilityService from '@/shared/services/ability_service'
import EventBus       from '@/shared/services/event_bus'
import EventService from '@/shared/services/event_service'
import { pick, assign } from 'lodash'

export default
  components:
    ThreadItem: -> import('@/components/thread/item.vue')

  props:
    event: Object
    isReturning: Boolean

  created: ->
    EventBus.$on 'stanceSaved', => EventBus.$emit 'refreshStance'
    @outcome = @poll.outcome()
    @watchRecords
      collections: ["stances", "outcomes"]
      query: (records) =>
        @myStance = @poll.myStance()
        @outcome = @poll.outcome()

  beforeDestroy: ->
    EventBus.$off 'showResults'
    EventBus.$off 'stanceSaved'

  data: ->
    buttonPressed: false
    myStance: null
    outcome: null

  computed:
    eventable: -> @event.model()
    poll: -> @eventable
    showResults: -> @poll.showResults()

    menuActions: ->
      assign(
        pick PollService.actions(@poll, @), ['edit_poll', 'close_poll', 'reopen_poll', 'notification_history', 'show_history', 'export_poll', 'print_poll', 'discard_poll', 'add_poll_to_thread']
      ,
        pick EventService.actions(@event, @), ['move_event', 'copy_url', 'pin_event', 'unpin_event']
      )
    dockActions: ->
      pick PollService.actions(@poll, @), ['show_results', 'hide_results', 'translate_poll', 'edit_stance', 'announce_poll', 'remind_poll']

