
import EventBus from '@/shared/services/event_bus'

export default
  data: ->
    flash: {}
    isOpen: false
    timeout: 6000
    message: ''
    options: {}
  created: ->
    EventBus.$on 'flashMessage', (flash) =>
      @flash = flash
      @isOpen = true
