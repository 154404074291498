
import { startCase, compact } from 'lodash'
import parseISO from 'date-fns/parseISO'
import AbilityService from '@/shared/services/ability_service'

export default
  props:
    group: Object
  methods:
    displayDate: (dateString) -> @exactDate(parseISO(dateString))
  computed:
    canSee: -> !@group.parentId && AbilityService.canAdminister(@group)
    showUpgradeButton: -> @group.subscription.plan == "trial"
    isActivePlan: -> ['pp-active-monthly', 'pp-active-annual', 'pp-community-annual', 'npap-active-monthly', 'npap-active-annual'].includes(@group.subscription.plan)
    tableData: ->
      {
        plan: startCase(@group.subscription.plan)
        state: startCase(@group.subscription.state)
        expires_at: @displayDate(@group.subscription.expires_at) if @group.subscription.plan == 'trial' && @group.subscription.expires_at
        renews_at: @displayDate(@group.subscription.renews_at) if @group.subscription.renews_at
        members: @group.orgMembersCount
        active_members: @group.subscription.members_count if @isActivePlan
        max_members: @group.subscription.max_members if @group.subscription.max_members
        max_threads: @group.subscription.max_threads if @group.subscription.max_threads
        chargify_link: "<a href=#{@group.subscription.management_link} target=_blank>#{@$t('subscription_status.manage_payment_details')}</a>" if @group.subscription.management_link
      }
