
import { fieldFromTemplate, myLastStanceFor } from '@/shared/helpers/poll'
import { max, values, orderBy, compact } from 'lodash'

export default
  props:
    poll: Object
  methods:
    countFor: (option) ->
      return null unless @poll.closingAt
      @poll.stanceData[option.name] or 0
    barTextFor: (option) ->
      compact([option.name, @countFor(option)]).join(" - ").replace(/\s/g, '\u00a0')
    percentageFor: (option) ->
      max_val = max(values(@poll.stanceData))
      return unless max_val > 0
      "#{100 * @countFor(option) / max_val}%"
    backgroundImageFor: (option) ->
      "url(/img/poll_backgrounds/#{option.color.replace('#','')}.png)"
    styleData: (option) ->
      'background-image': @backgroundImageFor(option)
      'background-size': "#{@percentageFor(option)} 100%"
  computed:
    pollOptions: -> @poll.pollOptions()
