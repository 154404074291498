import { render, staticRenderFns } from "./form_page.vue?vue&type=template&id=6b484f1d&lang=pug&"
import script from "./form_page.vue?vue&type=script&lang=coffee&"
export * from "./form_page.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import Loading from '@/components/common/loading.vue'
import PollCommonDirective from '@/components/poll/common/directive.vue'
import SubmitOverlay from '@/components/common/submit_overlay.vue'
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {Loading,PollCommonDirective,SubmitOverlay,VBtn,VCard,VCardActions,VCardTitle,VContainer,VIcon,VMain,VSpacer})
