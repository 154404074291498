
import { submitMembership } from '@/shared/helpers/form'
import Flash   from '@/shared/services/flash'
import { onError } from '@/shared/helpers/form'

export default
  props:
    membership: Object
  data: ->
    isDisabled: false
  methods:
    submit: ->
      @membership.save()
      .then =>
        Flash.success "membership_form.updated"
        @closeModal()
      .catch onError(@membership)

