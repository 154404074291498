
import PollFormOptionsMixin from '@/mixins/poll_form_options'

export default
  mixins: [PollFormOptionsMixin]

  props:
    poll: Object
    addOptionsOnly:
      type: Boolean
      default: false

  data: ->
    menu: false

