import { render, staticRenderFns } from "./stance_choice.vue?vue&type=template&id=41150b95&lang=pug&"
import script from "./stance_choice.vue?vue&type=script&lang=coffee&"
export * from "./stance_choice.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import MidDot from '@/components/common/mid_dot.vue'
import PollMeetingTime from '@/components/poll/meeting/time.vue'
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
installComponents(component, {MidDot,PollMeetingTime,VAvatar,VChip})
