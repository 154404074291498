
import AppConfig from '@/shared/services/app_config'
import Session from '@/shared/services/session'

export default
  props:
    user: Object
    preventClose: Boolean

  data: ->
    emailLogin: AppConfig.features.app.email_login
    siteName: AppConfig.theme.site_name
    privacyUrl: AppConfig.theme.privacy_url
    isDisabled: false
    pendingGroup: null

  created: ->
    @watchRecords
      key: 'authForm'
      collections: ['groups']
      query: (store) =>
        @pendingGroup = store.groups.find(@pendingIdentity.group_id)

  computed:
    userLocale: ->
      Session.user().locale

    isInvitedNewUser: ->
      AppConfig.pending_identity.email_verified == false

    loginComplete: ->
      @user.sentLoginLink or @user.sentPasswordLink

    pendingDiscussion: ->
      @pendingIdentity.identity_type == 'discussion_reader'

    pendingPoll: ->
      @pendingIdentity.identity_type == 'stance'

    pendingIdentity: ->
      (AppConfig.pending_identity || {})

