
import { differenceInDays, format, parseISO } from 'date-fns'
import Session         from '@/shared/services/session'
export default
  props:
    group: Object
  computed:
    isWasGift: ->
      @group.subscription.plan == 'was-gift'
    isTrialing: ->
      @group.membersInclude(Session.user()) && @group.subscription.plan == 'trial'
    isExpired: ->
      @isTrialing && !@group.subscription.active
    daysRemaining: ->
      differenceInDays(parseISO(@group.subscription.expires_at), new Date) + 1
    createdDate: ->
      format(new Date(@group.createdAt), 'do LLLL yyyy')
