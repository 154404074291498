import { render, staticRenderFns } from "./discussions_panel.vue?vue&type=template&id=7da5fcf2&lang=pug&"
import script from "./discussions_panel.vue?vue&type=script&lang=coffee&"
export * from "./discussions_panel.vue?vue&type=script&lang=coffee&"
import style0 from "./discussions_panel.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import TagsDisplay from '@/components/tags/display.vue'
import ThreadPreview from '@/components/thread/preview.vue'
import ThreadSearchResult from '@/components/thread/search_result.vue'
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {TagsDisplay,ThreadPreview,ThreadSearchResult,VBtn,VCard,VIcon,VLayout,VList,VListItem,VListItemTitle,VMenu,VSheet,VTextField})
