
import AuthService  from '@/shared/services/auth_service'
import { hardReload } from '@/shared/helpers/window'
import Session from '@/shared/services/session'
import AuthModalMixin from '@/mixins/auth_modal'
import AppConfig from '@/shared/services/app_config'
import Flash from '@/shared/services/flash'
import EventBus from '@/shared/services/event_bus'

export default
  mixins: [AuthModalMixin]
  props:
    user: Object

  data: ->
    siteName: AppConfig.theme.site_name
    vars: {}
    loading: false

  methods:
    signIn: ->
      @user.name = @vars.name if @vars.name?
      @loading = true
      AuthService.signIn(@user).finally =>
        @loading = false

    signInAndSetPassword: ->
      @loading = true
      @signIn().then =>
        @loading = false
        EventBus.$emit 'openModal',
          component: 'ChangePasswordForm'
          props:
            user: Session.user()

    sendLoginLink: ->
      @loading = true
      AuthService.sendLoginLink(@user).finally =>
        @loading = false

    submit: ->
      if @user.hasPassword or @user.hasToken
        @signIn()
      else
        @sendLoginLink()
