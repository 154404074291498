import { render, staticRenderFns } from "./vote_form.vue?vue&type=template&id=5ab55cfe&lang=pug&"
import script from "./vote_form.vue?vue&type=script&lang=coffee&"
export * from "./vote_form.vue?vue&type=script&lang=coffee&"
import style0 from "./vote_form.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import PollCommonStanceReason from '@/components/poll/common/stance_reason.vue'
import SubmitOverlay from '@/components/common/submit_overlay.vue'
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {PollCommonStanceReason,SubmitOverlay,VAvatar,VBtn,VLayout})
