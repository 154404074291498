
import AppConfig from '@/shared/services/app_config'
import AbilityService from '@/shared/services/ability_service'
import Records from '@/shared/services/records'
import RecordLoader from '@/shared/services/record_loader'
import EventBus       from '@/shared/services/event_bus'
import Session       from '@/shared/services/session'
import { debounce, some, every, compact, omit, values, keys, intersection } from 'lodash'

export default
  data: ->
    group: null
    polls: []
    loader: null
    pollTypes: AppConfig.pollTypes

  created: ->
    @onQueryInput = debounce (val) =>
      @$router.replace(@mergeQuery(q: val))
    , 500

    @group = Records.groups.find(@$route.params.key)

    @initLoader()

    @watchRecords
      collections: ['polls', 'groups', 'memberships']
      query: => @findRecords()

    @loader.fetchRecords().then =>
      EventBus.$emit 'currentComponent',
        page: 'groupPage'
        title: @group.name
        group: @group


  methods:
    initLoader: ->
      @loader = new RecordLoader
        collection: 'polls'
        params:
          exclude_types: 'group'
          group_key: @$route.params.key
          status: @$route.query.status
          poll_type: @$route.query.poll_type
          query: @$route.query.q
          subgroups: @$route.query.subgroups

    openSelectPollTypeModal: ->
      EventBus.$emit 'openModal',
                     component: 'PollCommonStartForm'
                     props:
                       group: @group
                       isModal: true

    findRecords: ->
      groupIds = switch (@$route.query.subgroups || 'mine')
        when 'all' then @group.organisationIds()
        when 'none' then [@group.id]
        when 'mine' then intersection(@group.organisationIds(), Session.user().groupIds())

      chain = Records.polls.collection.chain()
      chain = chain.find(groupId: {$in: groupIds})
      chain = chain.find(discardedAt: null)

      switch @$route.query.status
        when 'active'
          chain = chain.find({'closedAt': null})
        when 'closed'
          chain = chain.find({'closedAt': {$ne: null}})

      if @$route.query.poll_type
        chain = chain.find({'pollType': @$route.query.poll_type})

      if @$route.query.q
        chain = chain.where (poll) =>
          some [poll.title, poll.details], (field) =>
            every @$route.query.q.split(' '), (frag) -> RegExp(frag, "i").test(field)

      @polls = chain.simplesort('createdAt', true).limit(@loader.params.from + @loader.params.per).data()

  watch:
    '$route.query': ->
      @initLoader().fetchRecords()

  computed:
    canStartPoll: -> AbilityService.canStartPoll(@group)

