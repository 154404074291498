
import { hasContent, insertContent, removeContent } from '@/shared/services/ssr_content'

export default
  props:
    until: null
    diameter:
      type: Number
      default: 30

  data: ->
    canDisplay: hasContent()

  mounted: ->
    insertContent('#loading-placeholder')

  watch:
    until: (val) -> removeContent() if val
