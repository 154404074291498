
import EventBus from '@/shared/services/event_bus'
import Records from '@/shared/services/records'
import Session from '@/shared/services/session'
import Flash from '@/shared/services/flash'
import RecipientsAutocomplete from '@/components/common/recipients_autocomplete'
import StanceService from '@/shared/services/stance_service'
import {map, debounce, without, filter, uniq, uniqBy, some, find, compact} from 'lodash'

export default
  components:
    RecipientsAutocomplete: RecipientsAutocomplete

  props:
    poll: Object

  data: ->
    users: []
    userIds: []
    isMember: {}
    isMemberAdmin: {}
    isStanceAdmin: {}
    reset: false
    saving: false
    loading: false
    initialRecipients: []
    actionNames: []
    service: StanceService
    query: ''
    message: ''

  mounted: ->
    @actionNames = ['makeAdmin', 'removeAdmin', 'revoke'] # 'resend'

    @fetchStances()
    @updateStances()

    @watchRecords
      collections: ['stances', 'memberships', 'users']
      query: (records) => @updateStances()

  computed:
    someRecipients: ->
      @poll.recipientAudience ||
      @poll.recipientUserIds.length ||
      @poll.recipientEmails.length

  methods:
    canPerform: (action, poll, user) ->
      switch action
        when 'makeAdmin'
          poll.adminsInclude(Session.user()) && !@isStanceAdmin[user.id] && !@isMemberAdmin[user.id]
        when 'removeAdmin'
          poll.adminsInclude(Session.user()) && @isStanceAdmin[user.id]
        when 'revoke'
          poll.adminsInclude(Session.user())

    perform: (action, poll, user) ->
      @userIds = []
      @isMember = {}
      @isMemberAdmin= {}
      @isStanceAdmin= {}
      @service[action].perform(poll, user).then =>
        @fetchStances()

    inviteRecipients: ->
      @saving = true
      Records.announcements.remote.post '',
        poll_id: @poll.id
        recipient_audience: @poll.recipientAudience
        recipient_user_ids: @poll.recipientUserIds
        recipient_emails: @poll.recipientEmails
        recipient_message: @message
        exclude_members: true
      .then (data) =>
        count = data.stances.length
        Flash.success('announcement.flash.success', { count: count })
        @reset = !@reset
      .finally =>
        @saving = false

    toHash: (a) ->
      h = {}
      a.forEach (i) -> h[i] = true
      h

    newQuery: (query) ->
      @query = query
      @updateStances()
      @fetchStances()

    fetchStances: debounce ->
      @loading = true
      Records.fetch
        path: 'stances/users'
        params:
          exclude_types: 'poll group'
          poll_id: @poll.id
          query: @query
      .then (data) =>
        @isMember = @toHash(data['meta']['member_ids'])
        @isMemberAdmin = @toHash(data['meta']['member_admin_ids'])
        @isStanceAdmin = @toHash(data['meta']['stance_admin_ids'])
        @userIds = uniq compact @userIds.concat(map data['users'], 'id')
        @updateStances()
      .finally =>
        @loading = false
    , 300

    updateStances: ->
      chain = Records.users.collection.chain()
      chain = chain.find(id: {$in: @userIds})

      if @query
        chain = chain.find
          $or: [
            {name: {'$regex': ["^#{@query}", "i"]}},
            {email: {'$regex': ["#{@query}", "i"]}},
            {username: {'$regex': ["^#{@query}", "i"]}},
            {name: {'$regex': [" #{@query}", "i"]}}
          ]

      @users = chain.data()
