import { render, staticRenderFns } from "./page.vue?vue&type=template&id=b284239c&lang=pug&"
import script from "./page.vue?vue&type=script&lang=coffee&"
export * from "./page.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import LoadingContent from '@/components/common/loading_content.vue'
import ThreadPreviewCollection from '@/components/thread/preview_collection.vue'
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {LoadingContent,ThreadPreviewCollection,VAvatar,VCard,VCardTitle,VContainer,VImg,VMain})
