import { render, staticRenderFns } from "./select.vue?vue&type=template&id=da303d10&lang=pug&"
import script from "./select.vue?vue&type=script&lang=coffee&"
export * from "./select.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import DismissModalButton from '@/components/common/dismiss_modal_button.vue'
import SortableItem from '@/components/common/sortable_item.vue'
import SortableList from '@/components/common/sortable_list.vue'
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {DismissModalButton,SortableItem,SortableList,VBtn,VCard,VCardActions,VCardTitle,VChip,VIcon,VSpacer})
